import { Box, Flex, Text } from "@chakra-ui/react";
import { Hero, HeroHeader, PlayButton } from "../../../common";

const VideoListingsHeroTile = ({ bgImage, duration, link, subTitle, title }) => {
    return (
        <Hero aspectRatio={{ base: 1, md: 2 / 1, lg: 3 / 1 }} bgImage={bgImage}>
            <PlayButton link={link} />
            <Flex h="100%" direction={{ base: "column", md: "row" }}>
                <Flex
                    h="100%"
                    w={{ base: "100%", md: "40%" }}
                    direction={{ base: "column" }}
                    alignItems={{ base: "center", md: "flex-start" }}
                    justifyContent={{ base: "flex-end", md: "center" }}
                >
                    <Box sx={{ zIndex: 100 }}>
                        <HeroHeader variant="secondary" text={title} mb={2} noOfLines={1} />
                        <Text color="white" fontSize="20px" mb={2} noOfLines={2}>
                            {subTitle}
                        </Text>
                        <Text color="white" fontSize="20px">
                            {duration}
                        </Text>
                    </Box>
                </Flex>
            </Flex>
        </Hero>
    );
};

export default VideoListingsHeroTile;
