import { Container } from "@chakra-ui/react";
import { CardAccordion } from "../../../new";
import { constants } from "../../../../util/constants";

const VideoListingsCarousel = ({ category, videos }) => {
    const { containerSize } = constants;

    return (
        <Container maxW={containerSize}>
            <CardAccordion cards={videos} cardType="video" category={category} />
        </Container>
    );
};

export default VideoListingsCarousel;
