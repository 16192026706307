import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container } from "@chakra-ui/react";
import { TileHeader, WellnessCard } from "../../../common";
import { constants } from "../../../../util/constants";
import "swiper/swiper-bundle.min.css";

const WellnessListingsCarousel = (props) => {
    const { containerSize } = constants;
    const { category, articles, hasLink } = props;

    SwiperCore.use([Navigation]);

    // TODO add to utility file
    const toggleTouchMove = (numSlides) => (articles && articles.length > numSlides ? true : false);

    const settings = {
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 25,
                allowTouchMove: toggleTouchMove(1),
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 15,
                allowTouchMove: toggleTouchMove(2),
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 25,
                allowTouchMove: toggleTouchMove(3),
            },
        },
    };

    const slides =
        articles &&
        articles.map((article, i) => (
            <SwiperSlide key={i}>
                <WellnessCard
                    bgImage={article.data.external_image_source.url}
                    link={{ href: article.data.link.url }}
                    subTitle={article.data.short_description[0].text}
                    title={article.data.title[0].text}
                />
            </SwiperSlide>
        ));

    return (
        <Container maxW={containerSize}>
            <TileHeader
                variant="secondary"
                text={category}
                pb={5}
                hasLink={hasLink}
                link={{ text: "View All", href: "/wellness-this-week" }}
            />
            <Swiper
                navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                }}
                {...settings}
            >
                {slides}
                <div className="swiper-button-next"></div>
                <div className="swiper-button-prev"></div>
            </Swiper>
        </Container>
    );
};

export default WellnessListingsCarousel;
