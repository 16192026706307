import { Box, Flex, Icon } from "@chakra-ui/react";
import { Button, Hero, HeroHeader, HeroText } from "../../../common";
import { FaTag } from "react-icons/fa";
import { hiddenUntilAuthInit } from "../../../auth/index";

const PackageDetailsHero = ({ backgroundImageUrl, fromPrice, fromPriceDescription, link, title, isVoyager }) => {
    return (
        <Hero aspectRatio={{ base: 1, md: 2 / 1, lg: 3 / 1 }} bgImage={backgroundImageUrl}>
            <Flex h="100%" w="100%" justifyContent={{ base: "center" }} direction={{ base: "column", md: "row" }}>
                <Flex
                    h={{ base: "auto", md: "100%" }}
                    w={{ base: "100%", md: "75%", lg: "50%" }}
                    direction={{ base: "column" }}
                    alignItems={{ base: "center", md: "flex-start" }}
                    justifyContent={{ base: "center" }}
                >
                    <Box sx={{ zIndex: 100 }} mb={{ base: 5, md: 0 }}>
                        <HeroHeader variant="secondary" text={title} mb={4} />
                        {isVoyager && (
                            <Flex direction="row" alignItems="center">
                                <Icon
                                    as={FaTag}
                                    color="white"
                                    fontSize={{
                                        base: "26px",
                                        md: "30px",
                                        lg: "34px",
                                        xl: "36px",
                                    }}
                                    mr={2}
                                />
                                <Flex direction={{ base: "column" }}>
                                    <HeroText variant="secondary" text={fromPrice} textTransform="uppercase" />
                                    <HeroText variant="subdued" text={fromPriceDescription} />
                                </Flex>
                            </Flex>
                        )}
                    </Box>
                </Flex>
                <Flex
                    h={{ base: "auto", md: "100%" }}
                    w={{ base: "100%", md: "25%", lg: "50%" }}
                    direction={{ base: "column" }}
                    alignItems={{ base: "center", md: "flex-end" }}
                    justifyContent={{ base: "flex-end" }}
                >
                    {isVoyager && (
                        <Button
                            href={link.url}
                            label={link.label}
                            color="sand"
                            colorScheme="gold"
                            border="1px solid white"
                        />
                    )}
                </Flex>
            </Flex>
        </Hero>
    );
};

export default hiddenUntilAuthInit(PackageDetailsHero);
