import { Flex, Icon, Text } from "@chakra-ui/react";
import { MdLocationOn } from "react-icons/md";
import { IoMdBed } from "react-icons/io";
import { FaCalendarAlt } from "react-icons/fa";
import { hiddenUntilAuthInit } from "../../../auth/index";
interface PackageDetailsProps {
    location: string;
    name: string;
    nights: string;
    isVoyager: boolean;
}
const PackageDetails = (props: PackageDetailsProps) => {
    const { location, name, nights, isVoyager } = props;
    return (
        <Flex direction={{ base: "column", md: "row" }}>
            <Flex
                alignItems="center"
                py="10px"
                pr={{ base: 0, md: "20px" }}
                borderRight={{ base: "none", md: "1px solid black" }}
            >
                <Icon fontSize={{ base: "16px", md: "20px" }} as={MdLocationOn} mr="5px" />
                <Text fontSize={{ base: "16px", md: "20px" }}>{location}</Text>
            </Flex>
            {isVoyager && name && (
                <Flex
                    alignItems="center"
                    py="10px"
                    px={{ base: 0, md: "20px" }}
                    borderRight={{ base: "none", md: "1px solid black" }}
                >
                    <Icon fontSize={{ base: "20px", md: "26px" }} as={IoMdBed} mr="5px" />
                    <Text fontSize={{ base: "16px", md: "20px" }}>{name}</Text>
                </Flex>
            )}
            <Flex alignItems="center" py="10px" pl={{ base: 0, md: "20px" }}>
                <Icon fontSize={{ base: "16px", md: "20px" }} as={FaCalendarAlt} mr="5px" />
                <Text fontSize={{ base: "16px", md: "20px" }}>{nights} Nights</Text>
            </Flex>
        </Flex>
    );
};

export default hiddenUntilAuthInit<PackageDetailsProps>(PackageDetails);
