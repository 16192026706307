/**
 * hiddenUntilAuthInit
 *
 * A reusable higher order component (HOC) intended to wrap around pages to check Voyager status. Returns null until the user state is loaded.
 *
 */

import router from "next/router";
import React from "react";
import { authContext } from "@/services/hooks/useAuth";

export default function hiddenUntilAuthInit<P>(WrappedComponent, LoadingComponent?: React.FC) {
    return class HiddenUntilAuthInit extends React.Component<P> {
        static contextType = authContext;
        context: React.ContextType<typeof authContext>;
        props: P = null;

        constructor(props: P) {
            super(props);
            this.props = props;
        }

        render() {
            const { user, isLoading, isVoyager } = this.context;

            if (isLoading) {
                if (LoadingComponent) return <LoadingComponent />;
                return null;
            } else {
                return <WrappedComponent user={user} isVoyager={isVoyager} {...this.props} />;
            }
        }
    };
}
