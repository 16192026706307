import React from "react";
import { Box } from "@chakra-ui/react";
import { Coupon } from "..";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const CouponCarousel = ({ coupons, uid }) => {
    SwiperCore.use([Navigation]);

    // TODO add to utility file
    const toggleTouchMove = (numSlides) => (coupons.length > numSlides ? true : false);

    // TODO figure out best method for disabling swiper on small breakpoints in order to stack coupons
    const carouselSettings = {
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 24,
                allowTouchMove: toggleTouchMove(1),
            },
            768: {
                slidesPerView: coupons.length > 1 ? 2 : 1,
                spaceBetween: 24,
                allowTouchMove: toggleTouchMove(2),
            },
            992: {
                slidesPerView: coupons.length > 2 ? 3 : coupons.length === 2 ? 2 : 1,
                spaceBetween: 24,
                allowTouchMove: toggleTouchMove(3),
            },
        },
    };

    const couponCarousel = coupons.map((coupon, i) => {
        return (
            <SwiperSlide key={i} style={{ height: "auto" }}>
                <Coupon coupon={coupon} uid={uid} />
            </SwiperSlide>
        );
    });

    return (
        <Box w="100%">
            <Swiper
                navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                }}
                {...carouselSettings}
            >
                {couponCarousel}
                <div className="swiper-button-next"></div>
                <div className="swiper-button-prev"></div>
            </Swiper>
        </Box>
    );
};

export default CouponCarousel;
