import DeadEndLayout from "../components/layouts/DeadEndLayout";
import { useRouter } from "next/router";

function Error({ statusCode }) {
    const Router = useRouter();

    if (statusCode == 401) {
        // Unauthorized: used for logged-out users
        return (
            <DeadEndLayout
                headline="Members Only"
                subhead="You must be a member to view this page."
                buttonHref={"/join?path=" + Router.asPath}
                buttonCopy="Get Started"
            />
        );
    } else if (statusCode == 403) {
        // Forbidden: used for logged-in standard users
        return (
            <DeadEndLayout
                headline="Members Only"
                subhead="You must be a member to view this page."
                buttonHref={"/onboarding?path=" + Router.asPath}
                buttonCopy="Upgrade Your Account"
            />
        );
    } else if (statusCode == 404) {
        return (
            <DeadEndLayout
                headline="404 – Page Not Found"
                subhead="Just a quick layover—let’s get you back on your way to wellness."
                buttonHref={"/"}
                buttonCopy="Return Home"
            />
        );
    } else {
        const headlineText = "Error";
        return (
            <DeadEndLayout
                headline={headlineText}
                subhead="We're sorry, but something went wrong. Our team has been notified about the issue."
                buttonHref={"/"}
                buttonCopy="Return Home"
            />
        );
    }
}

Error.getInitialProps = ({ res, err }) => {
    const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
    return { statusCode };
};

export default Error;
