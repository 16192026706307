// TODO remove this component once all perks have been converted to v2

import { AspectRatio, Box, Container, Flex, Tag } from "@chakra-ui/react";
import { BackgroundGradient, Button, HeroHeader } from "../../../common";
import { constants } from "../../../../util/constants";
import { hiddenUntilAuthInit } from "../../../auth/index";

const PerkHeroTile = (props) => {
    const { bgImage, discount, headline, link, isVoyager } = props;
    const { aspectRatio, backgroundSettings, containerSize, gradient } = constants;
    const discountText = isVoyager ? discount : "Voyager Exclusive Offer";
    const buttonObject = isVoyager ? link : { label: "Join to See Offer", href: "/join" };

    return (
        <AspectRatio ratio={aspectRatio.normal}>
            <Box bgImage={bgImage} {...backgroundSettings}>
                <BackgroundGradient gradient={gradient.primary} />
                <Container variant="hero" maxW={containerSize}>
                    <Flex
                        h="100%"
                        w="100%"
                        justifyContent={{ base: "center" }}
                        direction={{ base: "column", md: "row" }}
                    >
                        <Flex
                            h={{ base: "auto", md: "100%" }}
                            w={{ base: "100%", md: "75%", lg: "50%" }}
                            direction={{ base: "column" }}
                            alignItems={{ base: "center", md: "flex-start" }}
                            justifyContent={{ base: "center" }}
                        >
                            <Box sx={{ zIndex: 100 }} pb={{ base: 5, md: 0 }}>
                                <Box pb={3}>
                                    <Tag
                                        size="md"
                                        bgColor="prussianBlue"
                                        color="white"
                                        borderColor="white"
                                        textTransform="uppercase"
                                        fontWeight="medium"
                                    >
                                        {discountText}
                                    </Tag>
                                </Box>
                                <HeroHeader variant="secondary" text={headline} mb={2} />
                            </Box>
                        </Flex>
                        <Flex
                            h={{ base: "auto", md: "100%" }}
                            w={{ base: "100%", md: "25%", lg: "50%" }}
                            direction={{ base: "column" }}
                            alignItems={{ base: "center", md: "flex-end" }}
                            justifyContent={{ base: "flex-end" }}
                        >
                            <Button {...buttonObject} variant="primaryWithOutline" />
                        </Flex>
                    </Flex>
                </Container>
            </Box>
        </AspectRatio>
    );
};

export default hiddenUntilAuthInit(PerkHeroTile);
