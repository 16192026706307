import { Container } from "@chakra-ui/react";
import { CardAccordion } from "../../../new";
import { constants } from "../../../../util/constants";

const PerkListingsCarousel = ({ category, perks }) => {
    const { containerSize } = constants;

    return (
        <Container maxW={containerSize}>
            <CardAccordion cards={perks} cardType="perk" category={category} />
        </Container>
    );
};

export default PerkListingsCarousel;
