import NextLink from "next/link";
import { Button, useBreakpointValue, useStyleConfig } from "@chakra-ui/react";

const QuizButton = (props) => {
    const { label, link, onClick, variant, ...rest } = props;

    const size = useBreakpointValue({
        base: "base",
        sm: "sm",
        md: "md",
        lg: "lg",
        xl: "xl",
    });

    const styles = useStyleConfig("QuizButton", { variant, size });

    const button = (
        <Button variant={variant} onClick={onClick} sx={styles} {...rest}>
            {label}
        </Button>
    );

    return link ? <NextLink href={link}>{button}</NextLink> : button;
};

export default QuizButton;
