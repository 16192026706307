import React from "react";
import { RichText } from "prismic-reactjs";
import { Box, Container, Flex, Grid, GridItem, Image } from "@chakra-ui/react";
import { PerkHeading } from "../../../../components/containers";

const PerkPartnerDetails = (props) => {
    const { details, logo, name } = props;

    return (
        <Container maxW="container.xl">
            <Grid templateColumns={"repeat(12, 1fr)"} gap={6}>
                <GridItem colSpan={{ base: 12, md: 8 }}>
                    <PerkHeading label={"About " + name} />
                    <Box color="prussianBlue">{RichText.render(details)}</Box>
                </GridItem>
                {logo && (
                    <GridItem colSpan={{ base: 12, md: 4 }}>
                        <Flex h="100%" align="center" justify="center">
                            <Box h="auto" w="100%">
                                <Image w="100%" src={logo.url} alt={logo.alt} />
                            </Box>
                        </Flex>
                    </GridItem>
                )}
            </Grid>
        </Container>
    );
};

export default PerkPartnerDetails;
