import React from "react";
import { AspectRatio, Container, Grid, GridItem, Image } from "@chakra-ui/react";
import { PerkHeading } from "../../../../components/containers";
import { generateAssetZenURL } from "../../../../util/helpers";

const PerkImageGallery = (props) => {
    const { images } = props;
    return (
        <Container maxW="container.xl">
            <PerkHeading label="Gallery" />
            <Grid w="100%" templateColumns={"repeat(12, 1fr)"} gap={6}>
                {images.map((image, i) => {
                    return (
                        <GridItem key={i} colSpan={{ base: 12, md: 4 }}>
                            <AspectRatio ratio={{ base: 1.75, md: 1 }}>
                                <Image
                                    rounded="lg"
                                    w="100%"
                                    h="100%"
                                    src={generateAssetZenURL(image.image_id, "assetzen", 735, 420)}
                                />
                            </AspectRatio>
                        </GridItem>
                    );
                })}
            </Grid>
        </Container>
    );
};

export default PerkImageGallery;
