import React from "react";
import { RichText } from "prismic-reactjs";
import { Box, Flex, Heading, Spacer } from "@chakra-ui/react";
import { Button } from "../../../../../components/common";

const Coupon = ({ coupon, uid }) => {
    return (
        <Box h="100%" rounded="lg" bg="prussianBlue" textAlign="left" p={2}>
            <Flex h="100%" direction="column" p={4} border="1px dashed rgba(255, 255, 255, 0.5)" borderRadius="10px">
                <Heading as="h3" color="white" size="sm" mb={5}>
                    {coupon.coupon_header}
                </Heading>
                <Box color="white" mb={5}>
                    {RichText.render(coupon.coupon_details)}
                </Box>
                <Spacer />
                <Box>
                    <Button
                        href={
                            coupon.coupon_link_url +
                            "?utm_source=Vacayou&utm_medium=affiliate+link&utm_campaign=Vacayou+Voyager+Club&utm_id=" +
                            uid
                        }
                        label={coupon.coupon_link_label ? coupon.coupon_link_label : "Claim Offer"}
                        color="prussianBlue"
                        colorScheme="platinum"
                        isExternal
                    />
                </Box>
            </Flex>
        </Box>
    );
};

export default Coupon;
