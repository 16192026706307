/**
 * Layout component for dead end pages
 *
 * Accepts the following parameters as props:
 * * headline
 * * subhead
 * * buttonHref
 * * buttonCopy
 *
 */

import React from "react";
import Head from "next/head";
import { BackgroundGradient, Button } from "../common";
import { Box, Center, Container } from "@chakra-ui/react";
import styles from "./DeadEndLayout.module.scss";
import { useRouter } from "next/router";
import * as Sentry from "@sentry/react";

export default function DeadEndLayout(props) {
    const gradient = "linear-gradient(to bottom, #524f38, #858266 21%, rgba(255, 255, 255, 0))";
    const { headline, subhead, action, buttonHref, buttonCopy } = props;
    const router = useRouter();

    const handleClick = (e) => {
        e.preventDefault();
        if (action === "goBack") {
            router.back();
        } else {
            router.push(buttonHref);
        }
    };

    // Log custom exception to Sentry
    Sentry.captureException({
        name: "DeadEndPage Viewed on " + router.pathname,
        fullPath: router.asPath,
        props: props,
    });

    return (
        <React.Fragment>
            <Head>
                <title>{headline}</title>
            </Head>
            <BackgroundGradient gradient={gradient} />
            <Container mt="auto" pt="88px">
                <Center h="100%" w="100%">
                    <Box textAlign="center" sx={{ zIndex: 100, padding: "50px 0" }}>
                        <h1 className={styles.headline}>{headline}</h1>
                        <p className={styles.subhead}>{subhead}</p>
                        <Button
                            href={buttonHref ? buttonHref : "#"}
                            onClick={handleClick}
                            label={buttonCopy}
                            color="sand"
                            colorScheme="gold"
                            border="1px solid white"
                        />
                    </Box>
                </Center>
            </Container>
        </React.Fragment>
    );
}
