import { Box, Flex } from "@chakra-ui/react";
import { Button, Hero, HeroHeader, HeroText } from "../../../common";

const WellnessListingsHeroTile = (props) => {
    const { bgImage, link, subTitle, title } = props;

    return (
        <Hero aspectRatio={{ base: 1, md: 2 / 1, lg: 3 / 1 }} bgImage={bgImage}>
            <Flex h="100%" w="100%" justifyContent={{ base: "center" }} direction={{ base: "column", md: "row" }}>
                <Flex
                    h={{ base: "auto", md: "100%" }}
                    w={{ base: "100%", md: "75%", lg: "50%" }}
                    direction={{ base: "column" }}
                    alignItems={{ base: "center", md: "flex-start" }}
                    justifyContent={{ base: "center" }}
                >
                    <Box sx={{ zIndex: 100 }} pb={{ base: 5, md: 0 }}>
                        <HeroHeader variant="secondary" text={title} mb={2} noOfLines={5} />
                        <HeroText variant="primary" text={subTitle} noOfLines={3} />
                    </Box>
                </Flex>
                <Flex
                    h={{ base: "auto", md: "100%" }}
                    w={{ base: "100%", md: "25%", lg: "50%" }}
                    direction={{ base: "column" }}
                    alignItems={{ base: "center", md: "flex-end" }}
                    justifyContent={{ base: "flex-end" }}
                >
                    <Button
                        href={link.url}
                        label={link.label}
                        color="sand"
                        colorScheme="gold"
                        border="1px solid white"
                        isExternal
                    />
                </Flex>
            </Flex>
        </Hero>
    );
};

export default WellnessListingsHeroTile;
