import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { Hero, HeroHeader, HeroText } from "../../../common";
import { InteractiveSearchToolbar } from "../../../common/SearchToolbar/SearchToolbar";

interface PackageListingSearchHeroTileProps {
    content: {
        copy: string;
        backgroundImage: string;
        headline: string;
    };
    search: {
        categoryQuery?: number;
        locationQuery?: string;
        redirect?: boolean;
        onSearch?: (categoryQuery: number, locationQuery: string) => void;
    };
}

const PackageListingsHero = (props: PackageListingSearchHeroTileProps) => {
    const { categoryQuery, locationQuery, onSearch, redirect } = props.search;
    const { copy, backgroundImage, headline } = props.content;

    return (
        <React.Fragment>
            <Hero aspectRatio={{ base: 1, md: 2 / 1, lg: 3 / 1 }} bgImage={backgroundImage}>
                <Flex h="100%" w="100%" justifyContent="center" direction={{ base: "column", md: "row" }}>
                    <Flex
                        h={{ base: "auto", md: "100%" }}
                        w="100%"
                        direction="column"
                        alignItems={{ base: "center", md: "flex-start" }}
                        justifyContent={{ base: "center" }}
                    >
                        <Box sx={{ zIndex: 100 }}>
                            <HeroHeader variant="secondary" text={headline} mb={2} noOfLines={3} />
                            <HeroText variant="primary" text={copy} />
                        </Box>
                    </Flex>
                </Flex>
            </Hero>

            <Box justifySelf="flex-end" sx={{ zIndex: 100 }}>
                <InteractiveSearchToolbar
                    redirect={redirect}
                    locationQuery={locationQuery}
                    categoryQuery={categoryQuery}
                    onSearch={(_categoryQuery, _locationQuery) => {
                        if (onSearch) {
                            onSearch(_categoryQuery, _locationQuery);
                        }
                    }}
                />
            </Box>
        </React.Fragment>
    );
};

export default PackageListingsHero;
