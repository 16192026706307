import React, { useEffect, useState } from "react";
import { Box, Button, Center, Container, Heading, Stack, useToast } from "@chakra-ui/react";
import { QuizButton, QuizBreadcrumb, QuizSlide } from "../..";
import { TileSubheader } from "../../../../common";
import { saveQuizSelections } from "@/services/admin-service";
import { constants } from "../../../../../util/constants";
import router from "next/router";
import * as Sentry from "@sentry/browser";

const QuizContainer = (props) => {
    // Set props and container constants
    const {
        allQuizOptions,
        quizSelections,
        setQuizSelections,
        customerId,
        subscriptionType,
        currentIndex,
        setCurrentIndex,
    } = props;
    const { containerSize } = constants;
    const toast = useToast();

    // Set state
    const [isLoading, setLoading] = useState(false);
    const [saveError, setSaveError] = useState(false);

    // Create pages and questions arrays
    const pages = allQuizOptions.map((question) => {
        return question.title;
    });
    const questions = allQuizOptions.map((question) => {
        return question.question_statement;
    });

    // Handle scrolling to top of page when next button is clicked
    useEffect(() => {
        document.getElementById("navigation").scrollIntoView({ behavior: "smooth" });
    }, [currentIndex]);

    // Add or remove items from quizSelections state
    const toggleQuizSelections = (option, isSelected) => {
        if (isSelected) {
            setQuizSelections([...quizSelections, option]);
        } else if (!isSelected) {
            setQuizSelections(
                quizSelections.filter((answer) => {
                    return answer !== option;
                })
            );
        }
    };

    const skipQuiz = () => {
        router.push("/onboarding/verify");
    };

    const nextPage = () => {
        if (currentIndex === allQuizOptions.length - 1) {
            skipQuiz();
        } else {
            setCurrentIndex(currentIndex + 1);
            setLoading(false);
        }
    };

    const lastPage = () => {
        setCurrentIndex(currentIndex - 1);
    };

    // Submit quizSelections on next/complete button click
    const submitQuizSelections = async () => {
        if (quizSelections.length > 0) {
            let saveResponse = await saveQuizSelections(quizSelections, customerId);
            if (saveResponse.status == 200) {
                nextPage();
            } else {
                toast({
                    title: `Something went wrong...`,
                    description: `This issue has been reported to our team. Please try again.`,
                    status: "warning",
                    isClosable: true,
                });
                Sentry.captureException({
                    message: `Unable to save quizSelections for customerId ${customerId}.`,
                    saveResponse: JSON.stringify(saveResponse),
                });
            }
        } else {
            nextPage();
        }
    };

    return (
        <React.Fragment>
            <Box
                h={{ base: "125px", lg: "auto" }}
                background={{
                    base: "linear-gradient(90deg, #003153 30%, white 30%)",
                    lg: "linear-gradient(90deg, #003153 50%, white 50%)",
                }}
            >
                <Container maxW={containerSize}>
                    <Stack direction={{ base: "column", md: "row" }} w="100%">
                        {/* This is the Question and Select all that apply title sections for quiz pages */}
                        <Box
                            justifyContent={"flex-start"}
                            borderRightRadius="50px"
                            padding="4"
                            w={{ lg: "70%", base: "100%" }}
                            h={{ base: "125px", lg: "auto" }}
                            background={"linear-gradient(to right, #003153, #858266)"}
                        >
                            <Heading
                                mb={3}
                                textTransform="uppercase"
                                fontWeight="medium"
                                letterSpacing="0.1em"
                                fontSize={{ base: "18px", md: "20px", lg: "25px" }}
                                color="white"
                            >
                                {questions[currentIndex]}
                            </Heading>
                            <TileSubheader text="Select all that apply" />
                        </Box>
                        <QuizBreadcrumb pages={pages} currentIndex={currentIndex} />
                    </Stack>
                </Container>
            </Box>
            <Box py={10} backgroundColor="#F3FAFF">
                <Container maxW={containerSize}>
                    <Box>
                        {allQuizOptions[currentIndex] && (
                            <QuizSlide
                                options={allQuizOptions[currentIndex].quiz_options}
                                quizSelections={quizSelections}
                                onClick={toggleQuizSelections}
                            />
                        )}
                    </Box>
                    <Box display="flex" flexDirection={{ base: "column", md: "row" }} pt={4}>
                        <Box
                            display="flex"
                            flex={1}
                            justifySelf="flex-start"
                            justifyContent={{ base: "center", md: "flex-start" }}
                            h={14}
                        >
                            {currentIndex !== 0 && (
                                <Button
                                    color="#858266"
                                    bgColor="#F3FAFF"
                                    w={{ base: "100%", md: "150px" }}
                                    h="50px"
                                    onClick={lastPage}
                                    variant="outline"
                                    colorScheme="gold"
                                    borderWidth="2px"
                                >
                                    Go Back
                                </Button>
                            )}
                        </Box>
                        <Box
                            display="flex"
                            flex={1}
                            justifySelf="flex-start"
                            justifyContent="center"
                            my={{ base: 3, md: 0 }}
                        >
                            <QuizButton
                                label={currentIndex < allQuizOptions.length - 1 ? "Continue" : "Complete"}
                                onClick={submitQuizSelections}
                                w={{ base: "100%", md: "60%", lg: "45%" }}
                                h="50px"
                                isLoading={isLoading}
                            />
                        </Box>
                        <Box
                            display="flex"
                            flex={1}
                            justifySelf="flex-start"
                            justifyContent={{ base: "center", md: "flex-end" }}
                        >
                            {subscriptionType === "Voyager" ? (
                                <Button
                                    w={{ base: "100%", md: "150px" }}
                                    h="50px"
                                    onClick={skipQuiz}
                                    variant="outline"
                                    colorScheme="gold"
                                    borderWidth="2px"
                                >
                                    Skip for now
                                </Button>
                            ) : null}
                        </Box>
                    </Box>
                </Container>
            </Box>
        </React.Fragment>
    );
};

export default QuizContainer;
