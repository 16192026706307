import Error from "../../pages/_error";
import React from "react";
import { authContext } from "@/services/hooks/useAuth";
import { LoadingPage } from "../common";
import { User } from "firebase/auth";

interface B2bOrg {
    id: number;
    name: string;
    alias: string;
}

interface Emails {
    type: string;
    email: string;
    verification_sent: string;
    verified_on: string | null;
}

interface Phones {
    type: string;
    phone: string;
    verification_sent: string | null;
    verified_on: string | null;
}

interface Customer {
    id: number;
    zen_id: number | null;
    firebase_id: string;
    hubspot_id: string | null;
    stripe_id: string | null;
    prefix: string | null;
    first_name: string | null;
    last_name: string | null;
    suffix: string | null;
    date_of_birth: string | null;
    emails: Emails[];
    phones: Phones[];
    nationality_country_code: string | null;
    residency_country_code: string | null;
    city: string | null;
    // TODO create addresses interface (DEV-1441)
    addresses: any[];
    passport_number: string | null;
    language_preference_code: string | null;
    communication: string | null;
    dietary_requirements: string | null;
    image_url: string | null;
    subscription_type: string | null;
    access_level: string | null;
    most_recent_visit: string | null;
    created_at: string | null;
    updated_at: string | null;
    total_spent: number | null;
    needs_attention: any[];
    // TODO create bookings interface (DEV-1441)
    bookings: any[];
    b2b_organizations: B2bOrg[];
}

// TODO export this and setup Typescript features (DEV-1411)
interface WithUserProps {
    customer: Customer;
    databaseId: string;
    user: User;
    userClaims: any;
    isLoading: boolean;
    isVoyager: boolean;
}

export default function withUser(
    WrappedComponent: React.FC<any>,
    noUserHandler?: (defaultAction: Function) => React.ReactElement
) {
    return class WithUser extends React.Component {
        static contextType = authContext;
        props = {};
        context: React.ContextType<typeof authContext>;

        constructor(props) {
            super(props);
            this.props = props;
        }

        render() {
            const { customer, databaseId, user, userClaims, isLoading, isVoyager } = this.context;

            if (isLoading) return <LoadingPage />;

            if (user === null) {
                const defaultAction = () => {
                    return <Error statusCode="401" />;
                };
                if (noUserHandler) {
                    return noUserHandler(defaultAction);
                }
                return defaultAction();
            }

            return (
                <WrappedComponent
                    customer={customer}
                    databaseId={databaseId}
                    user={user}
                    userClaims={userClaims}
                    isLoading={isLoading}
                    isVoyager={isVoyager}
                    {...this.props}
                />
            );
        }
    };
}
