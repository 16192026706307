import React from "react";
import { AspectRatio, Box, Container, Flex, Grid, GridItem, Skeleton, SkeletonText } from "@chakra-ui/react";

const PerkSkeleton = (props) => {
    return (
        <React.Fragment>
            <Box pb={{ base: 10, md: 14 }}>
                <AspectRatio
                    ratio={{
                        base: 0.67,
                        sm: 1.25,
                        md: 2,
                        lg: 2.5,
                        xl: 3,
                    }}
                >
                    <Skeleton height="100%" />
                </AspectRatio>
            </Box>
            <Container maxW="container.xl">
                <Box pb={{ base: 10, md: 14 }}>
                    <Grid templateColumns={"repeat(12, 1fr)"} gap={6}>
                        <GridItem colSpan={{ base: 12, md: 8 }}>
                            <SkeletonText w="50%" noOfLines={1} spacing={4} mb={5} />
                            <SkeletonText noOfLines={5} spacing={4} />
                        </GridItem>
                        <GridItem colSpan={{ base: 12, md: 4 }}>
                            <Flex h="100%" w="100%" direction="row" align="center" justify="center">
                                <Skeleton w="100%" height="100px" />
                            </Flex>
                        </GridItem>
                    </Grid>
                </Box>
            </Container>
            <Box>
                <Skeleton height="300px" />
            </Box>
        </React.Fragment>
    );
};

export default PerkSkeleton;
