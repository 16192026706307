import React from "react";
import { Flex, Heading } from "@chakra-ui/react";

const PerkHeading = (props) => {
    const { label } = props;

    return (
        <Flex direction="row" align="center" mb={5}>
            <Heading as="h2" variant="primary" color="prussianBlue" size="md">
                {label}
            </Heading>
        </Flex>
    );
};

export default PerkHeading;
