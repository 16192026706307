import { Box, Flex, Tag } from "@chakra-ui/react";
import { Button, Hero, HeroHeader, HeroText } from "../../../common";

const PerkListingsHeroTile = ({ copy, bgImage, discount, headline, link }) => {
    return (
        <Hero aspectRatio={{ base: 1, md: 2 / 1, lg: 3 / 1 }} bgImage={bgImage}>
            <Flex h="100%" w="100%" justifyContent={{ base: "center" }} direction={{ base: "column", md: "row" }}>
                <Flex
                    h={{ base: "auto", md: "100%" }}
                    w={{ base: "100%", md: "75%", lg: "50%" }}
                    direction={{ base: "column" }}
                    alignItems={{ base: "center", md: "flex-start" }}
                    justifyContent={{ base: "center" }}
                >
                    <Box sx={{ zIndex: 100 }} pb={{ base: 5, md: 0 }}>
                        <Box pb={3}>
                            <Tag
                                size="md"
                                bgColor="prussianBlue"
                                color="white"
                                borderColor="white"
                                textTransform="uppercase"
                                fontWeight="medium"
                            >
                                {discount}
                            </Tag>
                        </Box>
                        <HeroHeader variant="secondary" text={headline} mb={2} noOfLines={3} />
                        <HeroText variant="primary" text={copy} />
                    </Box>
                </Flex>
                <Flex
                    h={{ base: "auto", md: "100%" }}
                    w={{ base: "100%", md: "25%", lg: "50%" }}
                    direction={{ base: "column" }}
                    alignItems={{ base: "center", md: "flex-end" }}
                    justifyContent={{ base: "flex-end" }}
                >
                    <Button
                        href={link.url}
                        label={link.label}
                        color="sand"
                        colorScheme="gold"
                        border="1px solid white"
                    />
                </Flex>
            </Flex>
        </Hero>
    );
};

export default PerkListingsHeroTile;
