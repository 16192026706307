/**
 * withVoyager
 *
 * A reusable higher order component (HOC) intended to wrap around pages to check Voyager status.
 *
 */

import Error from "../../pages/_error";
import React from "react";
import { authContext } from "@/services/hooks/useAuth";
import { LoadingPage } from "../common";

export default function withVoyagerUser(
    WrappedComponent: React.FC<any>,
    noUserHandler?: (defaultAction: Function) => React.ReactElement,
    noVoyagerHandler?: (defaultAction: Function) => React.ReactElement
) {
    return class WithVoyagerUser extends React.Component {
        static contextType = authContext;
        context: React.ContextType<typeof authContext>;
        props = {};

        constructor(props) {
            super(props);
            this.props = props;
        }

        render() {
            const { user, isLoading, isVoyager } = this.context;

            if (isLoading) {
                return <LoadingPage />;
            }

            if (user === null) {
                const defaultAction = () => {
                    return <Error statusCode={401} />;
                };
                if (noUserHandler) {
                    return noUserHandler(defaultAction);
                }
                return defaultAction();
            }

            if (!isVoyager) {
                const defaultAction = () => {
                    return <Error statusCode={403} />;
                };
                if (noVoyagerHandler) {
                    return noVoyagerHandler(defaultAction);
                }
                return defaultAction();
            }

            return <WrappedComponent user={user} {...this.props} />;
        }
    };
}
