import { Circle, Flex, Text, Tooltip } from "@chakra-ui/react";

const QuizBreadcrumb = (props) => {
    const { pages, currentIndex, ...rest } = props;

    const dots = pages.map((page, i) => {
        if (currentIndex === i) {
            return (
                <Flex
                    key={i}
                    direction="row"
                    sx={{
                        maxWidth: "160px",
                        whiteSpace: "nowrap",
                        position: "relative",
                    }}
                >
                    <Tooltip hasArrow label={page} shouldWrapChildren>
                        <Circle h="10px" w={{ base: "36px", md: "40px" }} bg="gold.500" m="6px" />
                    </Tooltip>
                </Flex>
            );
        } else {
            return (
                <Tooltip key={i} hasArrow label={page} shouldWrapChildren>
                    <Circle h="10px" w={{ base: "16px", md: "20px" }} border="2px" borderColor="gold.500" m="6px" />
                </Tooltip>
            );
        }
    });

    return (
        <Flex
            direction="row"
            justifyContent={{ base: "center", lg: "flex-end" }}
            w={{ lg: "30%", base: "100%" }}
            py={2}
            alignItems="center"
        >
            <Text
                sx={{
                    color: "gold.500",
                    fontWeight: 600,
                    marginRight: "5%",
                }}
            >
                {pages[currentIndex]}
            </Text>
            {dots}
        </Flex>
    );
};

export default QuizBreadcrumb;
