// TODO remove this component once all perks have been converted to v2

import { AspectRatio, Box, Container } from "@chakra-ui/react";
import { Button, TileHeader, TileImage, TileText, BlurredTextCTA } from "../../../common";
import { constants } from "../../../../util/constants";
import { hiddenUntilAuthInit } from "../../../auth/index";

const PerkDetailsTile = (props) => {
    const { containerSize } = constants;
    const { copy, couponCode, headline, link, partner, isVoyager } = props;

    const partnerDetails =
        partner &&
        partner.data.content.map((detail, i) => {
            return <TileText key={i} text={detail.text} variant="secondary" />;
        });

    return (
        <Container maxW={containerSize}>
            <Box pb={10}>
                <TileHeader variant="primary" text={headline} pb={"24px"} />
                {!isVoyager && <BlurredTextCTA />}
                {isVoyager && <TileText text={copy} />}
                {isVoyager && <Button {...link} variant="primary" />}
            </Box>
            {partner && (
                <Box>
                    <Box pb={5}>
                        <AspectRatio
                            ratio={{
                                base: 2 / 1,
                                sm: 3 / 1,
                                md: 4 / 1,
                                lg: 5 / 1,
                            }}
                        >
                            <TileImage src={partner.data.featured_image.url} />
                        </AspectRatio>
                    </Box>
                    <TileHeader variant="secondary" text={"About " + partner.data.title[0].text} pb={3} />
                    {partnerDetails}
                </Box>
            )}
        </Container>
    );
};

export default hiddenUntilAuthInit(PerkDetailsTile);
