import React from "react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button, Collapse, Grid, GridItem, Show, useDisclosure } from "@chakra-ui/react";
import { TileHeader, PerkCard, VideoCard } from "../../common";
import "swiper/swiper-bundle.min.css";

const CardAccordion = ({ cards, cardType, category }) => {
    const { isOpen, onToggle } = useDisclosure();

    SwiperCore.use([Navigation, Pagination]);

    const toggleTouchMove = (visibleSlides) => cards.length > visibleSlides;

    const settings = {
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 25,
                allowTouchMove: toggleTouchMove(1),
            },
            480: {
                slidesPerView: 2,
                spaceBetween: 15,
                allowTouchMove: toggleTouchMove(2),
            },
        },
        pagination: { clickable: true },
    };

    const defaultCards = cards.slice(0, 4);
    const extraCards = cards.slice(4);

    const createCardArray = (cards) => {
        return cards.map((card, index) => {
            return (
                <GridItem colSpan={{ base: 12, sm: 6, lg: 3 }}>
                    {cardType === "video" && (
                        <VideoCard
                            key={index}
                            bgImage={card.data.video_url.thumbnail_url}
                            duration={card.data.video_url.duration}
                            link={`videos/${card.uid}`}
                            title={card.data.video_url.title}
                        />
                    )}
                    {cardType === "perk" && (
                        <PerkCard
                            key={index}
                            caption={card.data.short_description[0].text}
                            imageId={card.data.featured_image_id}
                            discount={card.data.featured_discount}
                            link={`perks/${card.uid}`}
                            subTitle={card.data.partner_name}
                            title={card.data.perk_name}
                            isRedesign
                        />
                    )}
                </GridItem>
            );
        });
    };

    const createCarousel = (cards) => {
        return (
            <Swiper {...settings}>
                {cards.map((card, index) => {
                    return (
                        <SwiperSlide key={index}>
                            {cardType === "video" && (
                                <VideoCard
                                    key={index}
                                    bgImage={card.data.video_url.thumbnail_url}
                                    link={`videos/${card.uid}`}
                                    title={card.data.video_url.title}
                                    duration={card.data.video_url.duration}
                                />
                            )}
                            {cardType === "perk" && (
                                <PerkCard
                                    key={index}
                                    bgImageID={card.data.featured_image_id}
                                    discount={card.data.featured_discount}
                                    link={`perks/${card.uid}`}
                                    perkPartner={card.data.partner_name}
                                    subTitle={card.data.short_description[0].text}
                                    title={card.data.perk_name}
                                    isRedesign
                                />
                            )}
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        );
    };

    return (
        <React.Fragment>
            <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                <GridItem colSpan={12}>
                    <TileHeader variant="secondary" text={category} pb={5} />
                </GridItem>
            </Grid>
            <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                <Show above="md">
                    <GridItem colSpan={12}>
                        <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                            {createCardArray(defaultCards)}
                        </Grid>
                    </GridItem>
                    <GridItem colSpan={12}>
                        <Collapse in={isOpen}>
                            <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                                {createCardArray(extraCards)}
                            </Grid>
                        </Collapse>
                    </GridItem>
                    {extraCards.length > 0 && (
                        <GridItem colSpan={12} sx={{ display: "flex", justifyContent: "center" }}>
                            <Button colorScheme="gold" onClick={onToggle}>
                                {isOpen ? "Hide" : "View All"}
                            </Button>
                        </GridItem>
                    )}
                </Show>
                <Show below="md">
                    <GridItem colSpan={12}>{createCarousel(cards)}</GridItem>
                </Show>
            </Grid>
        </React.Fragment>
    );
};

export default CardAccordion;
