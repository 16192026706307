import { AspectRatio, Box, Container, Flex, Stack } from "@chakra-ui/react";
import {
    BackgroundGradient,
    HeroHeader,
    HeroText,
    SignUpButton,
} from "../../../common";
import { constants } from "../../../../util/constants";

const PreviewHeroTile = (props) => {
    const { backgroundImage, copy, headline } = props;
    const { aspectRatio, backgroundSettings, containerSize, gradient } =
        constants;

    return (
        <AspectRatio ratio={aspectRatio.strong}>
            <Box
                bgImage={
                    "https://i.assetzen.net/i/" +
                    backgroundImage +
                    "/w:1440/h:694/q:70.jpg"
                }
                {...backgroundSettings}
            >
                <BackgroundGradient gradient={gradient.primary} />
                <Container variant="hero" maxW={containerSize}>
                    <Flex
                        h="100%"
                        justifyContent={{ base: "center" }}
                        direction={{ base: "row" }}
                    >
                        <Flex
                            h={{ base: "auto" }}
                            w={{ base: "100%" }}
                            direction="column"
                            alignItems={{ base: "center" }}
                            justifyContent={{ base: "center" }}
                        >
                            <Box sx={{ zIndex: 100 }} align="center">
                                <HeroHeader
                                    variant="exploded"
                                    text={headline}
                                    mb={1}
                                />
                                <HeroText
                                    variant="strong"
                                    text={copy}
                                    textTransform="uppercase"
                                    mb={4}
                                />
                                <SignUpButton variant="primaryWithOutline" />
                            </Box>
                        </Flex>
                    </Flex>
                </Container>
            </Box>
        </AspectRatio>
    );
};

export default PreviewHeroTile;
