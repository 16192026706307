import { Container, Grid } from "@chakra-ui/react";
import { PackageCard, TileHeader } from "../../../common";
import { constants } from "../../../../util/constants";
import "swiper/swiper-bundle.min.css";
import { generateAssetZenURL } from "../../../../util/helpers";

const PackageListingsGrid = (props) => {
    const { containerSize } = constants;
    const { category, trips, hasLink } = props;

    const cards = trips.map((trip, i) => (
        <PackageCard
            bgImage={generateAssetZenURL(trip.key_image, "assetzen", 314, 375)}
            title={trip.name}
            link={`/trips/` + trip.alias}
            aspectRatio={1 / 1.2}
        />
    ));

    return (
        <Container maxW={containerSize}>
            <TileHeader
                variant="secondary"
                text={category}
                pb={5}
                hasLink={hasLink}
                link={{ text: "View All", href: "/trips" }}
            />
            <Grid
                templateColumns={{
                    base: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                    md: "repeat(3, 1fr)",
                    lg: "repeat(4, 1fr)",
                }}
                gap={8}
            >
                {cards}
            </Grid>
        </Container>
    );
};

export default PackageListingsGrid;
