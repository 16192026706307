import React from "react";
import { useRouter } from "next/router";
import { Box, Button, Container, Text } from "@chakra-ui/react";
import { PerkHeading } from "../../../../../components/containers";
import { CouponCarousel } from "..";
import { hiddenUntilAuthInit } from "../../../../auth/index";

const PerkDetailsContainer = (props) => {
    const { coupons, isVoyager, uid } = props;

    const router = useRouter();

    return (
        <Container maxW="container.xl">
            <PerkHeading label="Offer Details" />
            {isVoyager ? (
                <CouponCarousel coupons={coupons} uid={uid} />
            ) : (
                <Box textAlign="center">
                    <Text color="prussianBlue" fontSize="lg" fontWeight="semibold" mb={1}>
                        Become A Member
                    </Text>
                    <Text color="prussianBlue" fontSize="lg" fontWeight="semibold" mb={3}>
                        To Unlock Full Details
                    </Text>
                    <Button
                        bg="gold.500"
                        fontSize="14px"
                        fontWeight="semibold"
                        _active={{ bg: "gold.500" }}
                        _hover={{ bg: "gold.500" }}
                        color="white"
                        variant="solid"
                        pl={8}
                        pr={8}
                        py={3}
                        onClick={() => router.push("/join")}
                    >
                        Join to See Offer
                    </Button>
                </Box>
            )}
        </Container>
    );
};

export default hiddenUntilAuthInit(PerkDetailsContainer);
