import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import { QuizOptionCard } from "../../../../../components/common";

const QuizSlide = (props) => {
    const { options, quizSelections, onClick } = props;
    const questionOptions = options.map((option, i) => (
        <QuizOptionCard
            key={i}
            label={option.title}
            icon={option.icon_code}
            bgImage={option.assetzen_image_id}
            isSelected={quizSelections.includes(option.id)}
            onClick={onClick}
            id={option.id}
        />
    ));
    // This is the grid that contains the selectable icons in the quiz
    return (
        <Grid
            templateColumns={{
                base: "repeat(2, 1fr)",
                sm: "repeat(2, 1fr)",
                md: "repeat(3, 1fr)",
                lg: "repeat(5, 1fr)",
            }}
            gap={4}
            mb={8}
            pt={4}
        >
            {questionOptions}
        </Grid>
    );
};

export default QuizSlide;
