import React from "react";
import { useRouter } from "next/router";
import {
    AspectRatio,
    Box,
    Container,
    Flex,
    Grid,
    GridItem,
    Heading,
    Stack,
    Tag,
    TagLabel,
    TagLeftIcon,
} from "@chakra-ui/react";
import { BlurredText, Button, Hero } from "../../../common";
import { FaTags } from "react-icons/fa";
import { generateAssetZenURL } from "../../../../util/helpers";
import { hiddenUntilAuthInit } from "../../../auth/index";

const PerkHero = (props) => {
    const { featuredDiscount, heroImageID, name, perkPartner, isVoyager } = props;

    const router = useRouter();

    return (
        <Hero
            aspectRatio={{ base: 1, md: 2 / 1, lg: 3 / 1 }}
            bgImage={{
                base: generateAssetZenURL(heroImageID, "assetzen", 767, 767),
                md: generateAssetZenURL(heroImageID, "assetzen", 1200, 400),
            }}
        >
            <Grid h="100%" w="100%" templateColumns="repeat(12, 1fr)">
                <GridItem colStart={{ base: 1, md: 3 }} colSpan={{ base: 12, md: 8 }}>
                    <Flex h="100%" w="100%" mx="auto" direction="column" align="center" justify="center">
                        <Box
                            bg="rgba(232, 233, 233, 0.92)"
                            boxShadow="primary"
                            textAlign="center"
                            rounded="lg"
                            w="100%"
                            p={{ base: 4, md: 9 }}
                        >
                            {perkPartner && (
                                <Heading
                                    as="h3"
                                    color="prussianBlue"
                                    size="md"
                                    fontWeight="semibold"
                                    textTransform="none"
                                    pb={2}
                                >
                                    {perkPartner}
                                </Heading>
                            )}
                            <Heading as="h2" color="prussianBlue" size="lg" fontWeight="semibold" pb={5}>
                                {name}
                            </Heading>
                            <Stack
                                direction={{ base: "column", sm: "row" }}
                                align="center"
                                justify="center"
                                spacing={5}
                                mx="auto"
                            >
                                {featuredDiscount && (
                                    <Tag bg="transparent" color="prussianBlue" variant="solid" fontWeight="semibold">
                                        <TagLeftIcon boxSize="16px" as={FaTags} />
                                        {isVoyager ? (
                                            <TagLabel textTransform="uppercase">{featuredDiscount}</TagLabel>
                                        ) : (
                                            <BlurredText color="prussianBlue" text="Lorem ipsum" />
                                        )}
                                    </Tag>
                                )}
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        isVoyager
                                            ? document.getElementById("offers").scrollIntoView({ behavior: "smooth" })
                                            : router.push("/join");
                                    }}
                                    label={isVoyager ? "View Offers" : "Join to See Offer"}
                                    color="sand"
                                    colorScheme="gold"
                                />
                            </Stack>
                        </Box>
                    </Flex>
                </GridItem>
            </Grid>
        </Hero>
    );
};

export default hiddenUntilAuthInit(PerkHero);
