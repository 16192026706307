import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container } from "@chakra-ui/react";
import { PackageCard, TileHeader } from "../../../common";
import { constants } from "../../../../util/constants";
import "swiper/swiper-bundle.min.css";
import { generateAssetZenURL } from "../../../../util/helpers";
import { IPackageListItem } from "@/services/package";

export interface PackageListingsCarouselProps {
    category: string;
    trips: IPackageListItem[];
    hasLink?: boolean;
    link?: {
        text: string;
        href: string;
    };
}
const PackageListingsCarousel = (props: PackageListingsCarouselProps) => {
    const { containerSize } = constants;
    const { category, trips, hasLink, link } = props;

    SwiperCore.use([Navigation]);

    // TODO add to utility file
    const toggleTouchMove = (numSlides) => (trips.length > numSlides ? true : false);

    const settings = {
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 25,
                allowTouchMove: toggleTouchMove(1),
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 15,
                allowTouchMove: toggleTouchMove(3),
            },
            992: {
                slidesPerView: 4,
                spaceBetween: 25,
                allowTouchMove: toggleTouchMove(4),
            },
        },
    };

    const slides = trips.map((trip, i) => (
        <SwiperSlide key={i}>
            <PackageCard
                bgImage={generateAssetZenURL(trip.key_image, "assetzen", 314, 375)}
                title={trip.name}
                link={`/trips/` + trip.alias}
                aspectRatio={1 / 1.2}
            />
        </SwiperSlide>
    ));

    return (
        <Container maxW={containerSize}>
            <TileHeader variant="secondary" text={category} pb={5} hasLink={hasLink} link={link} />
            <Swiper
                navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                }}
                {...settings}
            >
                {slides}
                <div className="swiper-button-next"></div>
                <div className="swiper-button-prev"></div>
            </Swiper>
        </Container>
    );
};

export default PackageListingsCarousel;
